<template>
  <el-dialog title="交易操作页面" :visible.sync="dialogVisible" width="79%" :before-close="handleClose">
    <el-form v-model="directFrom" ref="form" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="支行名称" width="120px"   prop="subbranch">
            <el-input width="20px" v-model="directFrom.subbranch" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="银行卡类型" width="120px"   prop="bankType">
            <el-input width="20px" v-model="directFrom.bankType" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="用途" width="140px"   prop="purpose">
            <el-input width="20px" v-model="directFrom.purpose" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="交易类型" width="120px">
            <el-radio-group v-model="directFrom.dealType" @change="dealTypeButton"  prop="dealType">
              <el-radio label="1" border>存入</el-radio>
              <el-radio label="2" border>支出</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="具体类型" width="120px">
            <el-select
              v-model="realTypeEntity"
              prop="realTypeEntity"
              filterable
              placeholder="请选择"
              style="width:70%"
              @change="getRealType"
            >
              <el-option
                v-for="item in bankOption"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="实际存入金额" width="120px"  prop="depositedMoney">
            <el-input width="20px" v-model="directFrom.depositedMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="本次交易金额" width="120px"  prop="money">
            <el-input-number width="20px" v-model="directFrom.money" @change="directMoneyButton"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="交易后的金额" width="120px"  prop="afterDepositedMoney">
            <el-input width="20px" v-model="directFrom.afterDepositedMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 借用实体 -->
      <el-row :gutter="21" v-if="this.specificType==22">
        <el-col :span="7">
          <el-form-item label="借走人的姓名" width="120px"  prop="borrowName"> 
            <el-input width="20px" v-model="addBankBorrowVO.borrowName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="借走人的别名" width="120px"  prop="borrowAlias">
            <el-input width="20px" v-model="addBankBorrowVO.borrowAlias"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="是否一定归还" width="120px" prop="isMustBack">
            <el-radio-group v-model="addBankBorrowVO.isMustBack">
              <el-radio label="1" border>是</el-radio>
              <el-radio label="2" border>否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 借用实体 -->
      <el-row :gutter="21" v-if="this.specificType==22">
        <el-col :span="7">
          <el-form-item label="借走日期" prop="borrowData" width="120px">
            <el-date-picker
              v-model="addBankBorrowVO.borrowData"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="预计归还日期" prop="preReturnData" width="120px">
            <el-date-picker
              v-model="addBankBorrowVO.preReturnData"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 借用实体 -->
      <el-row :gutter="21" v-if="this.specificType==23">
        <el-col :span="7">
          <el-form-item label="使用人" prop="userId" width="120px" >
            <el-input width="20px" v-model="directFrom.userId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="使用月份" prop="useMonth" width="120px">
            <el-date-picker
              v-model="directFrom.useMonth"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="归还月份" prop="borrowMonth" width="120px" >
            <el-date-picker
              v-model="directFrom.borrowMonth"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 提前使用 -->

      <el-row :gutter="21">
        <el-col :span="21">
          <el-form-item label="交易备注" width="120px"  prop="newRemark">
            <el-input width="20px" v-model="directFrom.newRemark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="directButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatDate } from "@/plugins/data.js";
import { getSelectById, directFundMoney } from "@/api/capital/bank/bank";
export default {
  name: "directFundMoney",
  data() {
    return {
      dialogVisible: false,
      directFrom: {
        isCunt: 2,
        realType: 1,
        subbranch: undefined,
        bankType: undefined,
        purpose: undefined,
        dealType: undefined,
        realTypeEntity: undefined,
        depositedMoney: 0,
        money: 0,
        afterDepositedMoney: 0,
        borrowName: 0,
        borrowAlias: 0,
        isMustBack: 0,
        borrowData: undefined,
        preReturnData: undefined,
        userId: undefined,
        useMonth: undefined,
        borrowMonth: undefined,
        newRemark: undefined
      },
      realTypeEntity: "",
      //选择的具体类型
      specificType: 1,
      addBankBorrowVO: {},
      isCunt: 2,
      radio1: "1",
      bankOption: [],
      payType: [
        {
          key: 21,
          value: "直接使用"
        },
        {
          key: 22,
          value: "借用"
        },
        {
          key: 25,
          value: "预支(伙食费)"
        }
      ],
      depositType: [
        {
          key: 1,
          value: "工资存入"
        },
        {
          key: 2,
          value: "正常存入"
        }
      ]
    };
  },
  methods: {
    //打开弹窗
    showFund(id) {
      this.dialogVisible = true;
      this.getSelectByIdButton(id);
      this.specificType=undefined
    },
    getSelectByIdButton(id) {
      getSelectById(id).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.directFrom = res.result;
      });
    },
    directMoneyButton() {
      //及时计算交易后金额
      var depositedMoney = Number(this.directFrom.depositedMoney);
      if (this.directFrom.dealType == 1) {
        this.directFrom.afterDepositedMoney =
          Number(depositedMoney) + Number(this.directFrom.money);
      } else {
        this.directFrom.afterDepositedMoney =
          Number(depositedMoney) - Number(this.directFrom.money);
      }
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    directButton() {
      this.directFrom.realType = this.realTypeEntity;
      if (this.directFrom.dealType == 2) {
        if (
          this.directFrom.newRemark == null ||
          this.directFrom.newRemark === undefined
        ) {
          return this.$message.error("支出备注需要填写");
        } else {
          if (this.directFrom.afterDepositedMoney < 0)
            return this.$message.error("没有这么多金额支出");
          this.directFrom.money = -this.directFrom.money;
        }
      }
      this.directFrom.addBankBorrowVO = this.addBankBorrowVO;
      console.log(this.directFrom);
      directFundMoney(this.directFrom).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.$refs["form"].resetFields();
        Object.assign(this.$data, this.$options.data())
        this.dialogVisible = false;
        this.$parent.getBankList();
      });
    },
    dealTypeButton() {
      this.specificType = null;
      if (this.directFrom.dealType == 2) {
        this.bankOption = this.payType;
      } else {
        this.bankOption = this.depositType;
      }
    },
    getRealType(value) {
      this.specificType = value;
    },
    // 表单重置
    reset() {
      this.directFrom = {
        subbranch: undefined,
        bankType: undefined,
        purpose: undefined,
        dealType: undefined,
        realTypeEntity: undefined,
        depositedMoney: 0,
        money: 0,
        afterDepositedMoney: 0,
        borrowName: 0,
        borrowAlias: 0,
        isMustBack: 0,
        borrowData: undefined,
        preReturnData: undefined,
        userId: undefined,
        useMonth: undefined,
        borrowMonth: undefined,
        newRemark: undefined
      };
      this.directFrom = this.$options.data().directFrom;
    }
  }
};
</script>
<style lang="less" scoped>
</style> 